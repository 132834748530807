/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useRef } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Peek from './peek'

const Carousel = (props) => {

  const slider = useRef()

  // useEffect(() => {
  //   if (!slider)
  //     return
  //   slider.slickGoTo(props.selected)
  // }, [props.selected])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    ...props.settings,
  }

  return (
    <Slider {...settings} className={props.className} ref={slider}>
      {props.children}
    </Slider>
  )

}

export { Peek }
export default Carousel