import { responsive } from '../../utils/helpers'

const button = {
  width: '60px',
  height: '60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  border: 'none',
  borderRadius: '50%',
  color: 'black',
  transition: 'opacity 0.2s linear',
  outline: 'none'
}

const styles = {
  arrows: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 18px',
  },
  button: button,
  buttonRight: {
    ...button,
    transform: 'rotate(180deg)'
  },
  count: {
    display: responsive('none', 'block'),
    position: 'absolute',
    top: '0',
    right: '18px',
    transform: 'translateY(calc(-100% - 10px))',

    'strong': {
      fontWeight: 700,
    }
  }
}

export default styles