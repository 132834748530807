/** @jsx jsx */
import { jsx, Text} from 'theme-ui';
import Glide from '@glidejs/glide'
import React, { useState, useEffect } from 'react'
import '@glidejs/glide/dist/css/glide.core.min.css'
import styles from './peek.style'
import Arrow from '../../images/carousel-arrow.svg'

const Peek = ({element = 'glide', options, children, arrows, count}) => {
  const [slider] = useState(new Glide(`.${element}`, options ))
  const [showNextArrow, setShowNextArrow] = useState(false)
  const [showPrevArrow, setShowPrevArrow] = useState(false)
  const [index, setIndex] = useState(0)
    
  useEffect(() => {
    slider.on('move.after', function() {
      setIndex(slider.index)
      setShowPrevArrow(slider.index <= 0 ? false : true)
      setShowNextArrow(slider.index >= children.length-1 ? false : true)
    })
    slider.mount()
    return () => slider.destroy()
  }, [
    slider,
    children.length,
  ])

  

  const Count = () => {
    if (!slider)
      return null
    return <Text sx={styles.count}><Text as="strong">Image {index + 1}</Text> of {children.length}</Text>
  }

  return (
    <div className={element}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {children.map((slide, index) => {
            return React.cloneElement(slide, {
              key: index,
              className: `glide__slide`
            })
          })}
        </ul>
      </div>
      {arrows &&
        <div class="glide__arrows" data-glide-el="controls" style={styles.arrows}>
          <button class="glide__arrow glide__arrow--left" data-glide-dir="<" style={{...styles.button, opacity: showPrevArrow ? 1 : 0}}><img src={Arrow} alt="<" /></button>
          <button class="glide__arrow glide__arrow--right" data-glide-dir=">" style={{...styles.buttonRight, opacity: showNextArrow ? 1 : 0}}><img src={Arrow} alt=">" /></button>
        </div>
      }  
      {count &&
        <Count />
      }
    </div>
  )
}

export default Peek